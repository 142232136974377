#header,
#footer {
  display: none;
}

.group-titre {
  .btn {
    display: none;
  }
}

.group-left,
.group-right {
  width: 50%;
  float: left;
}

.header__name-and-slogan {
  margin: auto;
}
